import { useMemo } from 'react';

import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { UserRole } from 'src/domain/models/user/user.model';
import useUser from 'src/presentation/modules/providers/user.provider';

interface CheckResult {
    /**
     * Indicates if the current user has the owner role
     */
    hasOwnerRole: boolean;
    /**
     * Indicates if the current user is the owner of the search
     * This can be achieved by having the `owner` role, or by being the owner of the search itself
     */
    currentUserIsSearchOwner: boolean;
    /**
     * The search owner
     */
    searchOwner: Colleague | undefined;
}

/**
 * Custom hook which checks if the current user is the owner of the search
 * @param searchSubscription The search subscription object to check
 * @returns Returns the result of the check
 */
export const useSearchOwnerChecker = (searchOwnerId?: number): CheckResult => {
    const { itemId, colleagues, roles, isImpersonated } = useUser();

    const hasOwnerRole = useMemo(
        () => roles.includes(UserRole.owner) ?? false,
        [roles],
    );

    const searchOwner = useMemo(
        () => colleagues.find((u) => u.itemId === searchOwnerId),
        [searchOwnerId, colleagues],
    );

    const currentUserIsSearchOwner = useMemo(
        () =>
            hasOwnerRole ||
            isImpersonated ||
            (!!searchOwner && searchOwner.itemId === itemId),
        [hasOwnerRole, isImpersonated, searchOwner, itemId],
    );

    return {
        hasOwnerRole,
        currentUserIsSearchOwner,
        searchOwner,
    };
};
