import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { DealsApi } from 'src/data/api/deal/deal.api';
import { LeadsApi } from 'src/data/api/leads/leads.api';
import { HubSpotService } from 'src/data/services/hubspot/hubspot.service';
import { LocationService } from 'src/data/services/location/location.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import {
    EventProps,
    MixpanelService,
} from 'src/data/services/mixpanel/mixpanel.service';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { ObjectivesStore } from 'src/data/stores/objectives/objectives.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    Alert,
    Deal,
    DealState,
    ProjectType,
} from 'src/domain/models/deal/deal.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { Objective } from 'src/domain/models/objective/objective.model';
import { SuccessState } from 'src/domain/models/success-state/success-state.model';
import { QuickLink } from 'src/presentation/modules/deal-view/components/page-quick-links/page-quick-links.component';
import { createToastForCopyClipboard } from 'src/presentation/shared/web-icons-panel/utils';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';
import { copyValueToClipboard } from 'src/utils/string.utils';

import { dealTypeToString } from './deal-view.feature.utils';
import { Language } from 'src/domain/models/locale/locale.model';
import {
    DealActivityType,
    OutreachActivityType,
} from 'src/app-features/deal-activity/domain/deal-activity.model';

export interface ObjectiveUpdateParams {
    state?: SuccessState;
    status?: string;
}

export interface DealActivityCreatePayload {
    dealId: string;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    subType: OutreachActivityType;
    customType?: string;
}

export interface DealViewFeature {
    isRelatedProjectLoading: boolean;
    dealsMap: Map<string, Deal>;
    projectsMap: Map<string, Lead>;
    isDealLoading: boolean;
    noDealExists: boolean;
    isDealFromDashboard: boolean;
    quickLinksElementsMap: Map<string, HTMLDivElement>;
    needToSyncDealAlert: boolean;
    fieldProgress: Map<string, BrTextInputStatus>;
    trackDealOpen: (
        dealId: string,
        source: string,
        path: string,
        omniSearchResultType: string | null,
        contactId: string | null,
        companyId?: string | null,
    ) => void;
    assignUserToDeal: (deal: Deal, userId: number) => Cancellable;
    addValueToClipboard: (
        value: string,
        t: TFunction<'translation', undefined>,
    ) => void;
    loadDealById: (id: string, onDone: () => void) => Cancellable;
    requestRelatedProject: (deal: Deal) => Cancellable;
    editDealNote: (dealId: string, value: string) => void;
    editDealTitle: (dealId: string, value: string) => void;
    editCustomEstimatedValue: (dealId: string, value: number) => void;
    showHubSpot: () => void;
    hideHubSpot: () => void;
    expandHubSpotChat: () => void;
    collapseHubSpotChat: () => void;
    setQuickLinksElements: (quickLinks: QuickLink[]) => void;
    syncDealAlert: (dealId: string) => void;
    clearFieldProgress: () => void;
    onDealSwitch: () => void;
    getLeadByDealId: (dealId: string) => Lead | undefined;
}

export class DealViewFeatureImpl implements DealViewFeature {
    t: TFunction<'translation', undefined> = getI18n().t;
    isAssignUserToDealLoading = false;
    isRelatedProjectLoading = false;
    isDealLoading = false;
    isExportLoading = false;
    noDealExists = false;
    quickLinksElementsMap: Map<string, HTMLDivElement> = new Map();
    fieldProgress = new Map();

    get dealsMap() {
        return this.dealsStore.dealsMap;
    }

    get projectsMap() {
        return this.leadsStore.leads;
    }

    get needToSyncDealAlert() {
        return this.dealsStore.needToSyncDealAlert;
    }

    getLeadByDealId = (dealId: string) => {
        const deal = this.dealsMap.get(dealId);
        return deal?.project
            ? this.projectsMap.get(deal.project.id)
            : undefined;
    };

    setAssignUserToDealLoading = (flag: boolean) => {
        this.isAssignUserToDealLoading = flag;
    };

    onDealAlertLoadedSyncIt = (dealId: string, alert: Alert) => {
        const deal = this.dealsMap.get(dealId ?? '');

        if (deal) {
            deal.alert = alert;
        }

        this.dealsStore.setNeedToSyncDealAlert(false);
    };

    syncDealAlert = (dealId: string) => {
        return handleRequest(
            this.dealsApi.getDealAlert,
            { id: dealId },
            (alert) => this.onDealAlertLoadedSyncIt(dealId, alert),
            doNothing,
            (error) => this.baseStore.onRequestFailed('sync-deal-alert', error),
        );
    };

    addValueToClipboard = (
        value: string,
        t: TFunction<'translation', undefined>,
    ) => {
        copyValueToClipboard(value);
        const toastMessage = createToastForCopyClipboard(value, t);
        this.toasterStore.showMessage(toastMessage);
    };

    setQuickLinksElements = (quickLinks: QuickLink[]) => {
        quickLinks.forEach(({ label, element }) =>
            this.quickLinksElementsMap.set(label, element),
        );
    };

    setLoadingRelatedProject = (loading: boolean) => {
        this.isRelatedProjectLoading = loading;
    };

    setLoadingDeal = (loading: boolean) => {
        this.isDealLoading = loading;
    };

    setNoDealExists = (flag: boolean) => {
        this.noDealExists = flag;
    };

    get isDealFromDashboard(): boolean {
        return this.locationService.isDealFromDashboard;
    }

    constructor(
        private dealsStore: DealsStore,
        private leadsStore: LeadsStore,
        private pipelineStore: PipelineStore,
        private leadsApi: LeadsApi,
        private dealsApi: DealsApi,
        private toasterStore: ToasterStore,
        private locationService: LocationService,
        private hubspotService: HubSpotService,
        private objectivesStore: ObjectivesStore,
        private userStore: UserStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    assignUserToDeal = (deal: Deal, userId: number): Cancellable => {
        return handleRequest(
            this.dealsApi.assignUserToDeal,
            { dealId: deal.id, assigneeId: userId },
            this.onSuccessAssignUserToDeal(deal, userId),
            (flag) =>
                flag && this.updateFieldProgress('deal_assignee', 'progress'),
            this.onError('assign-user-to-deal', 'deal_assignee'),
        );
    };

    trackDealOpen = (
        dealId: string,
        source: string,
        path: string,
        omniSearchResultType: string | null,
        contactId: string | null,
        companyId?: string | null,
    ) => {
        const deal = this.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        const dealType =
            deal.state && deal.state !== DealState.inPlay
                ? deal.state
                : deal.alert.type;
        const getDeal = () => deal;
        const user = this.userStore.user;
        if (user) {
            const data: EventProps = {
                dealType: dealTypeToString[dealType],
                dealOpenSource: source,
                dealHasOpenReminder: (deal.reminders?.length ?? 0) > 0,
                dealOwnerIsCurrentUser: deal.assigneeId === user.itemId,
                dealOpenSourceUrl: path,
                outreachAttemptCount: deal.outreachActivityLog.length,
            };

            if (contactId) {
                data['contactId'] = contactId;
            }

            if (companyId) {
                data['companyId'] = companyId;
            }

            if (omniSearchResultType) {
                data['omniSearchResultType'] = omniSearchResultType;
            }

            this.mixpanelService.trackEvent(
                MixpanelEventName.ClickedToOpenDeal,
                data,
                deal.id,
                getDeal,
                this.pipelineStore.getPipeline,
            );
        }
    };

    loadDealById = (id: string, onDone: () => void) => {
        this.setNoDealExists(false);
        return handleRequest(
            this.dealsApi.getDealById,
            { id },
            (resp) => this.onLoadDeal(resp, onDone),
            this.setLoadingDeal,
            (error) => this.onDealError('load-deal-by-id', error),
            'load-deal-by-id',
        );
    };

    onDealError = (errorKey: string, error?: Error) => {
        if (error) {
            this.setNoDealExists(true);
        }

        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t('deal_view.load_deal_failure_message'),
        });
    };

    onLoadDeal = (
        {
            deal,
            objectives,
        }: { deal: Deal | undefined; objectives: Objective[] },
        onDone: () => void,
    ) => {
        this.objectivesStore.clear();
        this.objectivesStore.setObjectives(objectives);

        if (!deal) {
            return this.onDealError(
                'load-deal-by-id',
                new Error(`404 - Deal resource not found`),
            );
        }

        this.dealsStore.dealsMap.set(deal.id, deal);
        onDone();
    };

    onLoadLead = (lead: Lead | undefined) => {
        if (lead) {
            this.leadsStore.leads.set(lead.id, lead);
        }
    };

    requestRelatedProject = (deal: Deal) => {
        /* 
        * We only need to load related project for BR projects (old structure).
        For the new neural search structure, the project details will be loaded inside the Deal model.
        */
        if (deal.project?.type !== ProjectType.Br) {
            return emptyCancellable;
        }

        const projectId = deal.project?.id;
        if (!projectId) {
            return emptyCancellable;
        }

        return handleRequest(
            this.leadsApi.getLeadById,
            {
                projectId: Number(projectId),
                language: this.userStore.user?.language ?? Language.En,
            },
            this.onLoadLead,
            this.setLoadingRelatedProject,
            (error) => this.baseStore.onRequestFailed('related-project', error),
        );
    };

    updateFieldProgress = (key: string, status: BrTextInputStatus) => {
        this.fieldProgress.set(key, status);
    };

    editDealNote = (dealId: string, value: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        handleRequest(
            this.dealsApi.editDealInformation,
            { dealId, note: value, dealRevenue: deal.dealRevenue },
            (ok) => {
                this.onDealNoteResponse(deal, value)(ok);
                this.updateFieldProgress('deal_note', 'success');
            },
            (flag) => flag && this.updateFieldProgress('deal_note', 'progress'),
            this.onError('edit-deal-note', 'deal_note'),
        );
    };

    editDealTitle = (dealId: string, dealTitle: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        handleRequest(
            this.dealsApi.editDealTitle,
            { dealId, dealTitle },
            this.onDealTitleResponse(deal, dealTitle),
            (flag) =>
                flag && this.updateFieldProgress('deal_title', 'progress'),
            this.onError('edit-deal-title', 'deal_title'),
        );
    };

    onError = (errorKey: string, field: string) => (error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error);
        error && this.updateFieldProgress(field, 'error');
    };

    onDealTitleResponse = (deal: Deal, title: string) => (ok: boolean) => {
        if (!ok) {
            return;
        }
        deal.title = title;
        this.updateFieldProgress('deal_title', 'success');
    };

    onDealNoteResponse = (deal: Deal, value: string) => (ok: boolean) => {
        if (!ok) {
            return;
        }
        deal.notes = value;
        this.pipelineStore.setNeedToRequestPipelines(true);
    };

    editCustomEstimatedValue = (dealId: string, value: number) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        handleRequest(
            this.dealsApi.editDealInformation,
            {
                dealId,
                note: deal.notes,
                dealRevenue: { currency: deal.dealRevenue.currency, value },
            },
            (ok) => {
                this.onDealValueResponse(deal, value)(ok);
                this.updateFieldProgress('deal_value', 'success');
            },
            (flag) =>
                flag && this.updateFieldProgress('deal_value', 'progress'),
            (error) => {
                this.baseStore.onRequestFailed(
                    'edit-custom-estimated-value',
                    error,
                );
                error && this.updateFieldProgress('deal_value', 'error');
            },
        );
        deal.dealRevenue.value = value;
    };

    onSuccessAssignUserToDeal =
        (deal: Deal, userId: number) => (ok: boolean) => {
            if (!ok) {
                return;
            }
            deal.assigneeId = userId > 0 ? userId : null;
            this.dealsStore.setNeedToSyncDealAlert(true);
            this.updateFieldProgress('deal_assignee', 'success');
        };

    onDealValueResponse = (deal: Deal, value: number) => (ok: boolean) => {
        if (!ok) {
            return;
        }
        deal.dealRevenue.value = value;
        this.pipelineStore.setNeedToRequestPipelines(true);
    };

    showHubSpot = () => {
        this.hubspotService.showHubSpot();
    };

    hideHubSpot = () => {
        this.hubspotService.hideHubSpot();
    };

    expandHubSpotChat = () => {
        this.hubspotService.expandHubSpotChat();
    };

    collapseHubSpotChat = () => {
        this.hubspotService.collapseHubSpotChat();
    };

    clearFieldProgress = () => {
        this.fieldProgress.clear();
    };

    onDealSwitch = () => {
        this.dealsStore.dealSwitchEvents.forEach((fn) => fn());
    };
}
