import { AbortParams } from 'src/data/api/api-client';
import {
    Comment,
    createComment,
} from 'src/domain/models/comment/comment.model';
import {
    DealContactCompany,
    DealContactPerson,
    createDealContactCompanyFromFragment,
    createDealContactPersonFromFragment,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    Deal,
    MinimalDeal,
    createActivityLog,
    createDeal,
    createMinimalDeal,
} from 'src/domain/models/deal/deal.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { OmniSearchResult } from '../domain/omni-search-result.model';
import {
    FilterKeys,
    FilterOperators,
    ReminderTargetType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    DealActivity,
    DealActivityType,
} from 'src/app-features/deal-activity/domain/deal-activity.model';

interface OmniSearchParams extends AbortParams {
    searchTerm: string;
}

interface OmniSearcResponse {
    comments: Comment[];
    companies: DealContactCompany[];
    contacts: DealContactPerson[];
    deals: Deal[];
    nextSteps: Reminder[];
    relatedDeals: MinimalDeal[];
    outreaches: DealActivity[];
}

export interface IOutreachStreakApi {
    search: (params: OmniSearchParams) => Promise<OmniSearcResponse>;
}

export const createOmniSearchApi = (
    processGqlSdk: ProcessGqlSdk,
): IOutreachStreakApi => {
    const search = async (
        params: OmniSearchParams,
    ): Promise<OmniSearcResponse> => {
        const response = await processGqlSdk.OmniSearch({
            ...params,
            limit: 10,
        });

        const {
            comments,
            companies,
            contacts,
            deals,
            nextSteps,
            activityLogs,
        } = response.omniSearch;

        const searchResults: OmniSearcResponse = {
            comments: comments.filter((c) => !!c.parentId).map(createComment),
            companies: companies
                .filter((c) => !!c.parentDeals?.length)
                .map(createDealContactCompanyFromFragment),
            contacts: contacts
                .filter((c) => !!c.parentDeals?.length)
                .map(createDealContactPersonFromFragment),
            deals: deals.map(createDeal),
            nextSteps: nextSteps
                .filter(
                    (r) =>
                        r.target.type === ReminderTargetType.Deal &&
                        !!r.target.id,
                )
                .map((r) => new Reminder(r)),
            outreaches: activityLogs
                .map(createActivityLog)
                .filter((a) => a.type === DealActivityType.outreachAttempt),
            relatedDeals: [],
        };

        // For the results of the search, we need to fetch the related deals so we can display them
        // preview UI. We do this by fetching all the deals that are related to the search results.
        const distinctDealIds = getDistinctDealIds(searchResults);
        const queryParams = {
            filtering: [
                {
                    field: FilterKeys.FkDealId,
                    fop: FilterOperators.FopIn,
                    values: distinctDealIds,
                },
            ],
        };

        const { dealsCollection } = await processGqlSdk.GetAllDealsCollection({
            queryParams,
        });

        return {
            ...searchResults,
            relatedDeals:
                (dealsCollection.edges ?? []).map((edge) =>
                    createMinimalDeal(edge.node),
                ) ?? [],
        };
    };

    return {
        search,
    };
};

const getDistinctDealIds = (result: OmniSearchResult): string[] =>
    Array.from(
        new Set([
            ...result.comments.map((comment) => comment.dealId),
            ...result.companies.map(
                (company) => company.parentDeals?.[0] ?? '',
            ),
            ...result.contacts.map((contact) => contact.parentDeals?.[0] ?? ''),
            ...result.deals.map((deal) => deal.id),
            ...result.nextSteps.map((nextStep) => nextStep.target.id),
            ...result.outreaches.map((activity) => activity.dealId ?? ''),
        ]),
    );
