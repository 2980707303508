import { makeAutoObservable } from 'mobx';
import Delta from 'quill-delta';

import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { doNothing } from 'src/utils/function.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import {
    DealActivity,
    DealActivityType,
    OutreachActivityType,
    OutreachNextAction,
    OutreachNextActionData,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { IDealActivityApi } from '../data/deal-activity.api';
import { getI18n } from 'react-i18next';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import {
    IDealActivityStore,
    OutreachActivationState,
} from './deal-activity.store';
import {
    filterDealActivitiesByCompanyId,
    filterDealActivitiesByContactId,
    OutreachActivityLaunchSource,
    OutreachActivityLogSource,
    OutreachActivityTypeToMetricsTabName,
    OutreachModeMetricsType,
} from './deal-activity.utils';
import { UserStore } from 'src/data/stores/user/user.store';
import { OutreachNextActionMetricsName } from '../presentation/outreach-log-base/next-best-actions/utils';

interface DealActivityCreatePayload {
    dealId: string;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    subType: OutreachActivityType;
    customType?: string;
}

export interface DealActivityUpdatePayload {
    activityLogId: string;
    dealId: string;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    customType?: string;
}

export interface DealActivityCreationMetrics {
    source?: OutreachActivityLogSource;
    oneClickNextAction?: OutreachNextAction;
}

export interface IDealActivityFeature {
    outreachNextAction: OutreachNextActionData;
    outreachActivationState: OutreachActivationState;
    setOutreachComment: (comment: Delta) => void;
    createDealActivity: (
        params: DealActivityCreatePayload,
        metrics?: DealActivityCreationMetrics,
    ) => Promise<boolean>;
    deleteDealActivity: (
        dealId: string,
        activityId: string,
    ) => Promise<boolean>;
    updateDealActivity: (params: DealActivityUpdatePayload) => Promise<boolean>;
    setOutreachNextAction: (action: OutreachNextActionData) => void;
    setOutreachActivationState: (
        state: Partial<OutreachActivationState>,
    ) => void;
    resetOutreachActivationState: () => void;
}

export class DealActivityFeature implements IDealActivityFeature {
    outreachNextAction: OutreachNextActionData = {
        enabled: false,
        action: OutreachNextAction.none,
        checkedObjectiveIds: [],
    };
    setOutreachComment = (comment: Delta) => {
        this.outreachNextAction.commentText = comment;
    };
    setOutreachNextAction = (action: OutreachNextActionData) =>
        (this.outreachNextAction = action);
    createDealActivity = async (
        params: DealActivityCreatePayload,
        metrics?: DealActivityCreationMetrics,
    ) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.createDealActivity,
                { ...params },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('create-deal-activity', err),
            );
            if (res) {
                this.onDealActivityCreated(params.dealId, res, metrics);
            }

            return !!res;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'create-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    deleteDealActivity = async (dealId: string, activityId: string) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.deleteDealActivity,
                { activityLogId: activityId },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('delete-deal-activity', err),
            );
            if (res) {
                this.onDealActivityDeleted(dealId, activityId);
            }
            return true;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'delete-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    updateDealActivity = async (params: DealActivityUpdatePayload) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.updateDealActivity,
                { ...params },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('update-deal-activity', err),
            );
            if (res) {
                this.onDealActivityUpdated(params.dealId, res);
            }

            return !!res;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'update-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    onDealActivityCreated = (
        dealId: string,
        activity: DealActivity,
        metrics?: DealActivityCreationMetrics,
    ) => {
        const deal = this.dealsStore.dealsMap.get(dealId);

        if (deal) {
            deal.outreachActivityLog = [activity, ...deal.outreachActivityLog];
            this.mixpanelService.trackEvent(
                MixpanelEventName.LoggedOutreachAttempt,
                {
                    outreachAttemptId: activity.id,
                    outreachAttemptCount: deal.outreachActivityLog.length,
                    outreachMode: this.getOutreachMode(activity.subType),
                    outreachNote: activity.notes,
                    resultType: activity.customType,
                    contactId: activity.contactId,
                    companyId: activity.relevantCompanyId,
                    outreachLogSource: metrics?.source?.toString(),
                    outreachModalSessionId: this.sessionId,
                    oneClickNextAction: metrics?.oneClickNextAction
                        ? OutreachNextActionMetricsName[
                              metrics?.oneClickNextAction
                          ]
                        : undefined,
                },
                dealId,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );

            const { t } = getI18n();
            this.toasterStore.showMessage({
                title: t('outreach_log.toast.successfully_saved'),
                autoClose: 1500,
                type: 'success',
            });
        }
    };

    onDealActivityDeleted = (dealId: string, activityId: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) return;
        const activity = deal?.outreachActivityLog.find(
            (a) => a.id === activityId,
        );
        if (deal && activity) {
            deal.outreachActivityLog = deal.outreachActivityLog.filter(
                (a) => a.id !== activityId,
            );
            this.mixpanelService.trackEvent(
                MixpanelEventName.DeletedOutreachAttempt,
                {
                    outreachAttemptCount: deal.outreachActivityLog.length,
                    outreachMode: activity.subType,
                    outreachAttemptId: activity.id,
                    outreachModalSessionId: this.sessionId,
                },
                dealId,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );
        }
    };

    onDealActivityUpdated = (dealId: string, activity: DealActivity) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) return;

        if (deal) {
            const result = deal.outreachActivityLog.findIndex(
                (a) => a.id === activity.id,
            );
            if (result < 0) return;
            deal.outreachActivityLog = [
                ...deal.outreachActivityLog.slice(0, result),
                activity,
                ...deal.outreachActivityLog.slice(result + 1),
            ];
            this.mixpanelService.trackEvent(
                MixpanelEventName.EditedOutreachAttempt,
                {
                    outreachAttemptCount: deal.outreachActivityLog.length,
                    outreachMode: activity.subType,
                    outreachAttemptId: activity.id,
                    outreachModalSessionId: this.sessionId,
                },
                dealId,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );
        }
    };

    onOutreachTabSwitchMetricsEvent = (
        dealId: string,
        prev: OutreachActivityType,
        current: OutreachActivityType,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.OutreachModalTabSwitched,
            {
                originTab: OutreachActivityTypeToMetricsTabName[prev],
                destinationTab: OutreachActivityTypeToMetricsTabName[current],
                outreachModalSessionId: this.sessionId,
            },
            dealId,
            this.dealsStore.getDeal,
            this.pipelineStore.getPipeline,
        );
    };

    onOutreachActivateMetricsEvent = (
        dealId: string,
        launchSource: OutreachActivityLaunchSource,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.OutreachModalOpened,
            {
                launchSource,
                outreachModalSessionId: this.sessionId,
            },
            dealId,
            this.dealsStore.getDeal,
            this.pipelineStore.getPipeline,
        );
    };

    get outreachActivationState() {
        return this.activityStore.outreachActivationState;
    }

    get sessionId() {
        return this.activityStore.sessionId;
    }

    getOutreachMode = (
        type: OutreachActivityType,
    ): OutreachModeMetricsType | undefined => {
        const { dealId, contact } = this.outreachActivationState;

        if (!type || !dealId || !contact) {
            return undefined;
        }

        if (type === OutreachActivityType.email) {
            const currentDeal = this.dealStore.getDeal(dealId);

            const isPerson = contact.type === 'person';
            const activities = isPerson
                ? filterDealActivitiesByContactId(
                      currentDeal?.outreachActivityLog || [],
                      contact.id,
                  )
                : filterDealActivitiesByCompanyId(
                      currentDeal?.outreachActivityLog || [],
                      contact.id,
                  );

            const hasEmailLog =
                activities.filter(
                    (activity) =>
                        activity.subType === OutreachActivityType.email,
                ).length > 1; // > 1 bc this is checked AFTER the log is created. So if there is only 1 email log, it means it's the first email log

            return hasEmailLog
                ? OutreachModeMetricsType.FOLLOW_UP_MAIL
                : OutreachModeMetricsType.FIRST_MAIL;
        } else {
            return type as unknown as OutreachModeMetricsType;
        }
    };

    setOutreachActivationState = (state: Partial<OutreachActivationState>) => {
        const prev = { ...this.outreachActivationState };
        this.activityStore.setOutreachActivationState(
            state,
            this.userStore.user?.itemId ?? 0,
        );
        if (
            prev.logOutreachChosenType &&
            state.logOutreachChosenType &&
            prev.logOutreachChosenType !== state.logOutreachChosenType
        ) {
            this.onOutreachTabSwitchMetricsEvent(
                this.outreachActivationState.dealId ?? '',
                prev.logOutreachChosenType,
                state.logOutreachChosenType,
            );
        } else if (state.launchSource && state.dealId && state.contact) {
            this.onOutreachActivateMetricsEvent(
                state.dealId,
                state.launchSource,
            );
        }
    };

    get resetOutreachActivationState() {
        return this.activityStore.resetOutreachActivationState;
    }

    constructor(
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private dealActivityApi: IDealActivityApi,
        private mixpanelService: MixpanelService,
        private toasterStore: ToasterStore,
        private activityStore: IDealActivityStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
        private dealStore: DealsStore,
    ) {
        makeAutoObservable(this);
    }
}
