import { Location as ReactLocation } from 'history';

import {
    Deal,
    DealOpenSource,
    DealState,
} from 'src/domain/models/deal/deal.model';
import { createPipelineRoute } from 'src/presentation/modules/pipeline/pipeline.utils';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

export const CommentsAnchor = 'comments';
export const DealActivityAnchor = 'deal-activity';
export const AddCommentAnchor = 'add-comment';
export const DealOpenActionParameter = 'source';
export const DealOpenPathParameter = 'path';
export const DealOpenLinkedContact = 'linked-contact';
export const DealOpenLinkedCompany = 'linked-company';
export const DealOpenLinkedOmniResultType = 'linked-omni-result-type';

export const encodeDealSource = (source: DealOpenSource) => {
    return `${DealOpenActionParameter}=${encodeURIComponent(source)}`;
};

export const encodePathSource = () => {
    return `${DealOpenPathParameter}=${encodeURIComponent(window.location.href)}`;
};

export const createDealPageLink = (dealId: string, source: DealOpenSource) => {
    return `/deals/${dealId}?${encodeDealSource(source)}&${encodePathSource()}`;
};

export const createDealPageReminderLink = (
    dealId: string,
    source: DealOpenSource,
    reminderId?: string,
) => {
    return `/deals/${dealId}/reminder/${reminderId ?? 'new'}?${encodeDealSource(
        source,
    )}&${encodePathSource()}`;
};

export const createDealPageLinkToComments = (
    dealId: string,
    source: DealOpenSource,
) => {
    return `/deals/${dealId}?${encodeDealSource(source)}&${encodePathSource()}#${CommentsAnchor}`;
};

export const createDealPageLinkToCommentInput = (
    dealId: string,
    source: DealOpenSource,
) => {
    return `/deals/${dealId}?${encodeDealSource(source)}&${encodePathSource()}#${AddCommentAnchor}`;
};

export const createDealBackLink = (
    deal: Deal,
    isDealFromDashboard: boolean,
) => {
    switch (deal.state) {
        case DealState.done:
            return AppRoute.markedDone;
        case DealState.disqualified:
            return AppRoute.disqualified;
        default:
            if (isDealFromDashboard) {
                return AppRoute.home;
            }
            return createPipelineRoute(deal.pipeline.id);
    }
};

export const getSearchTagFromLocation = (
    location: ReactLocation | Location,
): string => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const searchTag = params.get('search-tag');
    return searchTag ?? '';
};

export const getSearchTagForTracking = (
    location: ReactLocation | Location,
): string => {
    const tag = getSearchTagFromLocation(location);
    if (location.pathname.includes('project-lookup')) {
        return 'Project Lookup';
    } else if (tag !== '') {
        return tag;
    } else {
        return 'All searches selected';
    }
};
