import {
    BrAppEnvironment,
    getBrAppEnvironment,
} from 'src/utils/environment.utils';

const fallbackVersion = '_latest';

const localizationVersion: { [key in BrAppEnvironment]: { version: string } } =
    {
        development: {
            version:
                import.meta.env.VITE_LOCALIZATION_VERSION_DEV ??
                fallbackVersion,
        },
        beta: {
            version:
                import.meta.env.VITE_LOCALIZATION_VERSION_DEV ??
                fallbackVersion,
        },
        staging: {
            version:
                import.meta.env.VITE_LOCALIZATION_VERSION_PROD ??
                fallbackVersion,
        },
        production: {
            version:
                import.meta.env.VITE_LOCALIZATION_VERSION_PROD ??
                fallbackVersion,
        },
    };

export function getLocalizationVersion(): string {
    const environment = getBrAppEnvironment();
    return localizationVersion[environment].version;
}

// export class TranslationError extends Error {
//     constructor(msg: string) {
//         super(msg);
//         Object.setPrototypeOf(this, TranslationError.prototype);

//         this.name = 'TranslationError';
//     }
// }
