import { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { FolderType } from 'src/domain/models/folder/folder.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

import {
    DealOpenPathParameter,
    DealOpenActionParameter,
    getSearchTagFromLocation,
    DealOpenLinkedContact,
    DealOpenLinkedCompany,
    DealOpenLinkedOmniResultType,
} from './route.utils';

export const useFolderRoute = (): FolderType | undefined => {
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.replace('/', '');

    switch (path) {
        case FolderType.inbox:
        case FolderType.interesting:
        case FolderType.notInteresting:
        case FolderType.snoozed:
        case FolderType.done:
        case FolderType.exported: {
            return path;
        }
        case AppRoute.screening.replace('/', ''): {
            return FolderType.inbox;
        }
        default: {
            return undefined;
        }
    }
};

export const useSearchTag = (): string => {
    const location = useLocation();
    return getSearchTagFromLocation(location);
};

/**
 * A general function to get the currently active route of the app.
 * @returns the current active route
 */
export const useCurrentRoute = (): AppRoute | undefined => {
    const path = useLocation().pathname;
    const routes = Object.values(AppRoute);
    const foundRoute = routes.find((route) => route === path);
    return foundRoute;
};

export const useEmailRoute = (): string | undefined => {
    const location = useLocation();
    const { search } = location;
    const userEmail = search.replace('?email=', '');
    return userEmail ?? undefined;
};

export const useProjectCompanyRoute = (): boolean => {
    const location = useLocation<{ isFocusedCompany: boolean }>();
    return location.state?.isFocusedCompany || false;
};

export type DealsListRoute = AppRoute.markedDone | AppRoute.disqualified;

export const useDealsListRoute = (): DealsListRoute | undefined => {
    const location = useLocation();
    const { pathname } = location;
    switch (pathname) {
        case AppRoute.disqualified:
        case AppRoute.markedDone:
            return pathname;
        default:
            return undefined;
    }
};

export const useAnchorTag = (tag: string): boolean => {
    const location = useLocation();
    const { hash } = location;
    return hash === `#${tag}`;
};

export const useAnchorScroll = (
    tag: string,
    element: RefObject<HTMLDivElement>,
    timeout = 1000,
    scrollOptions?: ScrollIntoViewOptions,
): void => {
    const shouldScroll = useAnchorTag(tag);
    const isScrolled = useRef<boolean>(false);

    useEffect(() => {
        if (shouldScroll && element?.current) {
            setTimeout(() => {
                if (!isScrolled.current) {
                    element.current?.scrollIntoView(
                        scrollOptions ?? { behavior: 'auto' },
                    );
                    isScrolled.current = true;
                }
            }, timeout);
        }
    });
};

export const useAccountRoute = (): boolean => {
    const location = useLocation();
    const { pathname } = location;
    const parts = pathname.split('/').filter((param) => !!param);
    return parts.length > 1 && parts[1] === 'account';
};

export const useSubscriptionsRoute = (): boolean => {
    const location = useLocation();
    const { pathname } = location;
    const parts = pathname.split('/').filter((param) => !!param);
    return parts.length > 1 && parts[1] === 'search-subscriptions';
};

export const useURLParameter = (parameter: string): string | null => {
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const param = params.get(parameter);
    return param;
};

export const useConsumeURLParameter = (parameter: string): string | null => {
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const param = params.get(parameter);
    params.delete(parameter);

    //remove used parameter from url
    if (param) {
        window.history.replaceState(
            {},
            '',
            decodeURIComponent(
                `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`,
            ),
        );
    }
    return param;
};

export const useConsumeDealOpenURLParameters = () => {
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const sourcePath = params.get(DealOpenPathParameter);
    const sourceAction = params.get(DealOpenActionParameter);
    const omniSearchResultType = params.get(DealOpenLinkedOmniResultType);
    const linkedContactId = params.get(DealOpenLinkedContact);
    const linkedCompanyId = params.get(DealOpenLinkedCompany);

    params.delete(DealOpenPathParameter);
    params.delete(DealOpenActionParameter);
    params.delete(DealOpenLinkedOmniResultType);
    params.delete(DealOpenLinkedContact);
    params.delete(DealOpenLinkedCompany);

    if (
        sourcePath ||
        sourceAction ||
        linkedContactId ||
        linkedCompanyId ||
        omniSearchResultType
    ) {
        window.history.replaceState(
            {},
            '',
            decodeURIComponent(
                `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`,
            ),
        );
    }
    return {
        sourceAction,
        sourcePath,
        linkedContactId,
        linkedCompanyId,
        omniSearchResultType,
    };
};
