import { makeAutoObservable } from 'mobx';

import { DealsApi } from 'src/data/api/deal/deal.api';
import {
    FilterKeys,
    FilterOperators,
    SortingKeys,
    SortingOpQl,
    SortingOperators,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { buildQueryFilters } from 'src/data/api/pipeline/pipeline.api.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IPipelineDynamicFiltersStore } from 'src/data/stores/pipeline-data/dynamic-filters/pipeline-dynamic-filters.store.interface';
import { PipelineTableViewStore } from 'src/data/stores/pipeline-data/pipeline-table-view/pipeline-table-view.store';
import { IPipelinePerformanceViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/pipeline-performance-view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { PipelineDeal } from 'src/domain/models/deal/deal.model';
import {
    RefetchPipelineViewDataSource,
    getPipelineViewDataTypeBasedOnVisualizationMode,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { SortingSetting } from 'src/domain/models/settings/settings.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

/**
 * Interface for holding the offset pagination state for the table.
 * We send this data to our server side pagination mechanism
 * IMPORTANT: The pageSize is not included here, because it is a preference
 */
export interface PaginationState {
    offset?: number;
}

export interface IPipelineTableViewFeature {
    /**
     * Map containing the fetched deals according the pipeline
     */
    pipelineDeals: Map<string, PipelineDeal>;

    /**
     * The total deals count returned from the server upon a call to `requestPipelineDeals`
     */
    totalPipelineDealsCount: number;

    /**
     * ˜Flag which indicates that the deals are being fetched
     */
    fetchingDeals: boolean;

    /**
     * Keeps track of the pagination state used by our server pagination mechanism
     */
    currentPaginationState: PaginationState;

    /**
     * Sets the pagination state used by our server pagination mechanism
     */
    setCurrentPaginationState: (state: PaginationState) => void;

    /**
     * Sets the keyword used to filter the results. also will set page to 1.
     */
    setCurrentSearchKeyword: (keyword?: string) => void;

    /**
     * Request the deals for a given pipeline to be displayed in the pipeline performance view
     * in the `Table view mode`. The returned deals are then, set into the `pipelineDeals` map
     * @param pipelineId Id of the pipeline
     */
    requestPipelineDeals: (pipelineId: string) => Promise<void>;

    /**
     * Assigns an user to the be the owner of the deal
     * @param userId The id of the user to be assigned
     * @param dealId The id of the deal
     */
    assignOwnerToDeal: (userId: number, dealId: string) => Promise<void>;

    /**
     * Refresh the data of a deals table + dynamic filters after a cell
     * update, such as th owner or reminders.
     */
    triggerPipelineDataRefreshAfterCellUpdate: () => void;
}

export class PipelineTableViewFeature implements IPipelineTableViewFeature {
    constructor(
        private dealApi: DealsApi,
        private pipelineTableViewStore: PipelineTableViewStore,
        private pipelineViewPreferencesStore: IPipelinePerformanceViewPreferencesStore,
        private pipelineDynamicFiltersStore: IPipelineDynamicFiltersStore,
        private pipelineStore: PipelineStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    isFetching = false;
    currentPaginationState: PaginationState = {};
    currentSearchKeyword?: string;

    get pipelineDeals() {
        return this.pipelineTableViewStore.pipelineDeals;
    }

    get totalPipelineDealsCount() {
        return this.pipelineTableViewStore.totalPipelineDealsCount;
    }

    get fetchingDeals() {
        return this.isFetching;
    }

    setFetchingStatus = (isFetching: boolean) => (this.isFetching = isFetching);

    setCurrentPaginationState = async (state: PaginationState) => {
        this.currentPaginationState = state;

        const { currentSelectedPipeline } = this.pipelineStore;
        if (currentSelectedPipeline) {
            await this.requestPipelineDeals(currentSelectedPipeline.id);
        }
    };

    setCurrentSearchKeyword = async (keyword?: string) => {
        this.currentSearchKeyword = keyword;

        const { currentSelectedPipeline } = this.pipelineStore;
        if (currentSelectedPipeline) {
            await this.requestPipelineDeals(currentSelectedPipeline.id);
        }
    };

    requestPipelineDeals = async (pipelineId: string) => {
        const { offset } = this.currentPaginationState;

        const pageSize =
            this.pipelineViewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesPaginationRows,
            )! as number;

        const customParameterTag =
            this.accountConfigurationStore.customParameterTag;
        const viewPreferences =
            this.pipelineViewPreferencesStore.getCurrentPipelineViewPreferencesParams(
                customParameterTag?.name,
            );

        const filters = buildQueryFilters(
            viewPreferences,
            customParameterTag,
            true,
            this.currentSearchKeyword,
        );

        const dealsCollection = await handleRequestAsync(
            this.dealApi.getDealsByPipeline,
            {
                options: {
                    filtering: [
                        ...filters,
                        {
                            field: FilterKeys.FkDealPipelineId,
                            fop: FilterOperators.FopEq,
                            value: pipelineId,
                        },
                    ],
                    dynamicFiltering: Array.from(
                        this.pipelineDynamicFiltersStore.selectedDynamicFilters,
                    ),
                    pagination: { first: pageSize, offset },
                    sorting: this.mapSortingSettingsToSortingDealParams(),
                },
            },
            this.setFetchingStatus,
            (error) =>
                this.baseStore.onRequestFailed('request-pipeline-deals', error),
            'requestPipelineDeals',
        );

        dealsCollection &&
            this.pipelineTableViewStore.setFreshRequestedDeals(dealsCollection);
    };

    assignOwnerToDeal = async (assigneeId: number, dealId: string) => {
        await handleRequestAsync(
            this.dealApi.assignUserToDeal,
            { dealId, assigneeId },
            undefined,
            (error) =>
                this.baseStore.onRequestFailed('assign-owner-to-deal', error),
        );

        this.triggerPipelineDataRefreshAfterCellUpdate();
    };

    triggerPipelineDataRefreshAfterCellUpdate = () => {
        this.pipelineViewPreferencesStore.setRefetchPipelineViewDataState({
            active: true,
            refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                this.pipelineViewPreferencesStore.visualizationModePreference,
            ),
            source: RefetchPipelineViewDataSource.tableCellUpdate,
        });
    };

    mapSortingSettingsToSortingDealParams = (): SortingOpQl[] | undefined => {
        const preferences =
            this.pipelineViewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesSorting,
            ) as SortingSetting[];

        const sortingParams: SortingOpQl[] = preferences.map((preference) => {
            const sortingKey = preference.field.toString() as SortingKeys;
            let sortingOperator = preference.sop.toString() as SortingOperators;

            /**
             * This if is very specific for the `DealClosestNextStepDate` sorting key. As requested
             * by the BE team, we need to invert the sorting operator when the sorting key is this one, as
             * in terms of BE logic, it sorting ASC actually means DESC and vice-versa, but we still need to display the proper
             * sorting icon controls in the table header
             */
            if (sortingKey === SortingKeys.SkDealClosestNextStepDate) {
                sortingOperator =
                    sortingOperator === SortingOperators.SopAsc
                        ? SortingOperators.SopDesc
                        : SortingOperators.SopAsc;
            }

            return {
                field: sortingKey,
                sop: sortingOperator,
            };
        });

        return sortingParams.length ? sortingParams : undefined;
    };
}
