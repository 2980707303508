import { print } from 'graphql';

import { AbortParams } from 'src/data/api/api-client';
import {
    AskQuestionQuery,
    AskQuestionRealtimeDocument,
    AskQuestionRealtimeSubscriptionVariables,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { doNothing } from 'src/utils/function.utils';
import { checkWebSocket } from 'src/utils/websocket.utils';

interface GetQuestionParams extends AbortParams {
    /**
     * The question to ask
     */
    question: string;
    /**
     * The project id -- optional. Is used when asking questions using the old document structure
     */
    projectId?: string;
    /**
     * The document id -- optional. Is used when asking questions using the new document structure.
     * When asking question for neural search based projects, we pass the article ID here.
     */
    documentId?: string;
    /**
     * The deal id -- optional. Is used when asking questions using the new neural search project structure.
     */
    dealId?: string;
    onNextValue?: (value: string) => void;
}

export interface XRayApi {
    getAnswerHybrid: (params: GetQuestionParams) => Promise<string>;
}

export const createXRayApi = (processGqlSdk: ProcessGqlSdk): XRayApi => {
    const getAnswerHybrid = async (params: GetQuestionParams) => {
        if (await checkWebSocket()) {
            return getAnswerSubscription(params);
        }
        return getAnswerGraphql(params);
    };

    const getAnswerGraphql = async ({
        projectId,
        question,
        documentId,
        dealId,
        signal,
    }: GetQuestionParams) => {
        const projectIdValue = projectId ? +projectId : null;

        const response = await processGqlSdk.AskQuestion(
            { question, projectId: projectIdValue, documentId, dealId },
            { signal },
        );
        return response.actionFeatureXray.answer;
    };

    const getAnswerSubscription = async ({
        projectId,
        question,
        documentId,
        dealId,
        onNextValue = doNothing,
        signal,
    }: GetQuestionParams): Promise<string> => {
        let answer = '';
        const projectIdValue = projectId ? +projectId : null;

        return new Promise((resolve, reject) => {
            processGqlSdk.runSubscription<AskQuestionQuery>(
                {
                    query: print(AskQuestionRealtimeDocument),
                    variables: {
                        question,
                        projectId: projectIdValue,
                        documentId,
                        dealId,
                    } as AskQuestionRealtimeSubscriptionVariables,
                },
                (value) => {
                    answer += value.actionFeatureXray.answer;
                    onNextValue(answer);
                },
                () => resolve(answer),
                (error) => reject(error),
                signal,
            );
        });
    };

    return {
        getAnswerHybrid,
    };
};
