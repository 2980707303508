import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    ContactExtractionResult,
    ContactExtractionResultStatus,
} from './contact-extraction.model';
import { ContactExtractionStatus as ExtractionAPIResultStatus } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { AbortParams } from 'src/data/api/api-client';
import { languageModelToRaw } from 'src/domain/models/deal-export/deal-export.model';
import { Language } from 'src/domain/models/locale/locale.model';

interface GetContactExtractionParams extends AbortParams {
    dealId: string;
    language: Language;
}

interface GetAutoExtractionResultParams extends AbortParams {
    dealId: string;
}

export interface IContactExtractionApi {
    getAutoExtractionResult: (
        params: GetAutoExtractionResultParams,
    ) => Promise<ContactExtractionResult>;
    runAutoExtraction: (params: GetContactExtractionParams) => Promise<boolean>;
}

export const createContactExtractionApi = (
    processGqlSdk: ProcessGqlSdk,
): IContactExtractionApi => {
    const getAutoExtractionResult = async (
        params: GetAutoExtractionResultParams,
    ): Promise<ContactExtractionResult> => {
        const { getContactExtractionResult: result } =
            await processGqlSdk.GetContactExtractionResult(
                {
                    dealId: params.dealId,
                },
                {
                    signal: params.signal,
                },
            );
        const neverRun = result === null;
        const noContacts =
            result?.status === ExtractionAPIResultStatus.Success &&
            !result.extractedCompanies?.length &&
            !result.extractedContacts?.length;
        const status = neverRun
            ? ContactExtractionResultStatus.NeverRun
            : noContacts
              ? ContactExtractionResultStatus.NoContacts
              : extractionStatusMap[
                    result?.status ?? ExtractionAPIResultStatus.Failed
                ];
        return {
            status,
            contactCandidates: result?.extractedContacts ?? [],
            companyCandidates: result?.extractedCompanies ?? [],
        };
    };

    const runAutoExtraction = async (
        params: GetContactExtractionParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.RunContactExtractionForDeal(
            {
                dealId: params.dealId,
                language: languageModelToRaw[params.language],
            },
            { signal: params.signal },
        );
        return response.runContactExtractionForDeal.ok;
    };

    return {
        getAutoExtractionResult,
        runAutoExtraction,
    };
};

const extractionStatusMap: Record<
    ExtractionAPIResultStatus,
    ContactExtractionResultStatus
> = {
    [ExtractionAPIResultStatus.Failed]: ContactExtractionResultStatus.Error,
    [ExtractionAPIResultStatus.Success]: ContactExtractionResultStatus.Success,
    [ExtractionAPIResultStatus.InProgress]:
        ContactExtractionResultStatus.InProgress,
};
