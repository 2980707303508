import { css } from '@emotion/react';

export const resetStyles = css`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        border: 0;
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        margin-block: 0;
        margin-inline: 0;
        font-weight: normal;
    }

    button,
    input {
        -webkit-appearance: none;
        background: none;
    }

    input:-webkit-autofill {
        background-color: var(--white-background) !important;
    }

    input,
    select,
    textarea,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    button:not(:disabled) {
        cursor: pointer;
    }

    button:disabled {
        pointer-events: none;
    }

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    textarea,
    input,
    button,
    select {
        font-family: inherit;
        font-size: inherit;
    }
`;
