import { BrDrawer, DrawerVariant } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import objectHash from 'object-hash';
import { FC, useEffect } from 'react';

import { ContactDetailInfoPanelContainer } from 'src/app-features/contact/presentation/contact-panel/contact-detail-info-panel.container';
import { ContactEnrichmentPanelContainer } from 'src/app-features/contact-enrichment/presentation/contact-enrichment-panel.container';
import { GoogleEnrichmentPanelContainer } from 'src/app-features/google-enrichment/persentation/google-enrichment-panel.container';
import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { ContactExtractionDrawerContainer } from 'src/app-features/contact-extraction/presentation/contact-extraction-drawer/contact-extraction-drawer.container';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { ZIndex } from 'src/resources/styles/zIndex';

import { EmailTemplatePanelContainer } from './actions/email-template/email-template-panel.container';
import { ReminderPanelContainer } from './actions/reminder/reminder-panel.container';
import { useUrlActive } from 'src/utils/hooks/use-router';
import { AppRoute } from '../router/app-route.list';

interface EnablementActionsPanelComponentProps {
    isOpened: boolean;
    action?: ActionFeaturesIdentifiers;
    selectedEntityId?: string;
    selectedEntity: unknown;
    cleanAction: () => void;
}

export const EnablementActionsPanelComponent: FC<EnablementActionsPanelComponentProps> =
    observer(
        ({
            isOpened,
            action,
            selectedEntity,
            selectedEntityId,
            cleanAction,
        }) => {
            useEffect(() => cleanAction, [cleanAction]);
            const quickActionHash = objectHash({
                id: selectedEntityId,
                selectedEntity,
                action,
            });
            const enrichmentHash = objectHash({ id: selectedEntityId, action });
            const isMobile = useMediaQuery(MediaQuery.phone);

            const isOnDealPage = useUrlActive(AppRoute.deals);

            const closeOnOutsideClick =
                action === ActionFeaturesIdentifiers.xray && !isOnDealPage;
            return (
                <BrDrawer
                    isDrawerOpened={isOpened}
                    onCloseRequested={cleanAction}
                    shouldDisplayBackButton={false}
                    zIndex={ZIndex.zDrawer}
                    hideBackdrop
                    showHeader={false}
                    backgroundColor="var(--white)"
                    variant={
                        closeOnOutsideClick
                            ? DrawerVariant.temporary
                            : DrawerVariant.persistent
                    }
                    isMobile={isMobile}
                    applyBoxShadow
                >
                    {action === ActionFeaturesIdentifiers.schedule_reminder && (
                        <ReminderPanelContainer />
                    )}
                    {isOnDealPage && (
                        <>
                            {action ===
                                ActionFeaturesIdentifiers.contact_extractor && (
                                <ContactExtractionDrawerContainer />
                            )}
                            {action ===
                                ActionFeaturesIdentifiers.google_enrichment && (
                                <GoogleEnrichmentPanelContainer />
                            )}
                            {action ===
                                ActionFeaturesIdentifiers.email_templates && (
                                <EmailTemplatePanelContainer />
                            )}

                            {action ===
                                ActionFeaturesIdentifiers.contact_enrichment && (
                                <ContactEnrichmentPanelContainer
                                    key={enrichmentHash}
                                />
                            )}
                        </>
                    )}
                    {action === ActionFeaturesIdentifiers.contact_panel && (
                        <ContactDetailInfoPanelContainer
                            key={quickActionHash}
                        />
                    )}
                </BrDrawer>
            );
        },
    );
