import { add } from 'date-fns';
import { TFunction } from 'i18next';
import { DealContactPersonForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactType,
    DealContactUnionType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    OutreachActivityResult,
    OutreachNextAction,
    OutreachNextActionContactCreateData,
    OutreachNextActionData,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { ReminderTargetType } from 'src/domain/models/reminder/reminder-target-type';
import { getFullName } from 'src/domain/models/user/user.model';

const AllOutreachNextActions = Object.values(OutreachNextAction);
export const NextStepOutreachNextActions = AllOutreachNextActions.filter((a) =>
    a.includes('reminder'),
);

export const PauseDealOutreachNextActions = AllOutreachNextActions.filter((a) =>
    a.includes('pause'),
);

const OutreachResultToAction: Record<
    OutreachActivityResult,
    OutreachNextAction
> = {
    [OutreachActivityResult.didNotConnect]: OutreachNextAction.reminderTomorrow,
    [OutreachActivityResult.other]: OutreachNextAction.reminderNextWeek,
    [OutreachActivityResult.interested]: OutreachNextAction.moveDeal,
    [OutreachActivityResult.referral]: OutreachNextAction.createContact,
    [OutreachActivityResult.futureInterest]:
        OutreachNextAction.pauseDealThreeMonths,
    [OutreachActivityResult.notInterested]: OutreachNextAction.disqualifyDeal,
    [OutreachActivityResult.emailSent]: OutreachNextAction.none,
};

export const getDefaultOutreachNextActionData = (
    result: OutreachActivityResult,
    dealInplay: boolean,
    isNotLastStage: boolean,
): OutreachNextActionData => {
    const action = OutreachResultToAction[result];
    const noDefaultAction =
        action === OutreachNextAction.none ||
        (PauseDealOutreachNextActions.includes(action) && !isNotLastStage) ||
        (action === OutreachNextAction.moveDeal && !isNotLastStage) ||
        (action === OutreachNextAction.disqualifyDeal && !dealInplay);
    const selectedAction = noDefaultAction
        ? OutreachNextAction.reminderTomorrow
        : action;
    return {
        action: selectedAction,
        reminderDate: getNextActionDefaultReminderDate(selectedAction),
        pauseDealUntil: getNextActionDefaultPauseDealDate(selectedAction),
        contactCreationData:
            getNextActionDefaultContactCreationData(selectedAction),
        commentText: undefined,
        checkedObjectiveIds: [],
        enabled: !noDefaultAction,
    };
};

export const getEmailNBADefaultReminderDate = () => {
    return add(new Date(), { days: 3 });
};

export const getNextActionDefaultPauseDealDate = (
    action?: OutreachNextAction,
) => {
    switch (action) {
        case OutreachNextAction.pauseDealOneMonth:
            return add(new Date(), { months: 1 });
        case OutreachNextAction.pauseDealThreeMonths:
            return add(new Date(), { months: 3 });
        case OutreachNextAction.pauseDealCustom:
            return add(new Date(), { months: 3 });
        default:
            return undefined;
    }
};

export const getNextActionDefaultReminderDate = (
    action?: OutreachNextAction,
) => {
    switch (action) {
        case OutreachNextAction.reminderTomorrow:
            return add(new Date(), { days: 1 });
        case OutreachNextAction.reminderNextWeek:
            return add(new Date(), { days: 7 });
        case OutreachNextAction.reminderNextMonth:
            return add(new Date(), { months: 1 });
        case OutreachNextAction.reminderCustom:
            return new Date();
        default:
            return undefined;
    }
};
export const getNextActionDefaultContactCreationData = (
    action: OutreachNextAction,
): OutreachNextActionContactCreateData | undefined => {
    if (action !== OutreachNextAction.createContact) {
        return undefined;
    }
    return {
        firstName: '',
        lastName: '',
        relevantCompanyId: undefined,
        email: undefined,
        mobile: undefined,
        telephone: undefined,
    };
};
export const OutreachNextActionMetricsName: Record<OutreachNextAction, string> =
    {
        [OutreachNextAction.none]: 'Unchecked',
        [OutreachNextAction.reminderTomorrow]: 'Follow up tomorrow',
        [OutreachNextAction.reminderNextWeek]: 'Remind me next week',
        [OutreachNextAction.reminderNextMonth]: 'Remind me next month',
        [OutreachNextAction.reminderCustom]: 'Remind me on a specific date',
        [OutreachNextAction.createContact]: 'Create contact',
        [OutreachNextAction.moveDeal]: 'Move deal to next stage',
        [OutreachNextAction.pauseDealOneMonth]: 'Pause deal for 1 month',
        [OutreachNextAction.pauseDealThreeMonths]: 'Pause deal for 3 months',
        [OutreachNextAction.pauseDealCustom]: 'Pause deal until specific date',
        [OutreachNextAction.disqualifyDeal]: 'Disqualify deal',
    };

export const createNBAReminderPayload = (
    userId: number,
    dueDate: Date,
    contact: DealContactUnionType,
    dealId: string,
    t: TFunction,
    forEmail = false,
) => ({
    assigneeId: userId,
    dueDate: dueDate,
    description: t(
        forEmail
            ? `outreach_log.next_best_action.next_step_note_email`
            : 'outreach_log.next_best_action.next_step_note',
        {
            name:
                contact.type === DealContactType.person
                    ? getFullName(contact.firstName, contact.lastName)
                    : contact.name,
        },
    ),
    target: {
        type: ReminderTargetType.Deal,
        id: dealId,
    },
});

export const createNBAContactPayload = (
    data: OutreachNextActionContactCreateData,
): DealContactPersonForm => ({
    formType: DealContactType.person,
    firstName: data.firstName,
    lastName: data.lastName,
    isKey: true,
    role: '',
    notes: '',
    relevantCompanyId: data.relevantCompanyId,
    extracted: false,
    customInput: {
        customValues: [
            data.email && {
                name: PredefinedCustomFields.mail,
                value: data.email,
            },
            data.mobile && {
                name: PredefinedCustomFields.mobile,
                value: data.mobile,
            },
            data.telephone && {
                name: PredefinedCustomFields.telephone,
                value: data.telephone,
            },
        ].filter(Boolean) as { name: string; value: string }[],
    },
});
