import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { ReminderResponse } from 'src/data/api/actions/reminders/api-responses';
import { IRemindersApi } from 'src/data/api/actions/reminders/reminders.interface';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IQuickActionsStoreInterface } from 'src/data/stores/actions/quick-actions.store.interface';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { ErrorsStore } from 'src/data/stores/errors/errors.store';
import { NotificationsStore } from 'src/data/stores/notifications/notifications.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { TargetTypeModelToSdkMap } from 'src/domain/models/reminder/reminder-target-type';
import {
    CreateReminderData,
    Reminder,
    UpdateReminderData,
} from 'src/domain/models/reminder/reminder.model';
import { getNumberOfDaysAgo } from 'src/utils/datetime.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { IRemindersFeature } from './reminders-interface.feature';

const REMINDER_CREATION_ERROR_IDENTIFIER = 'create-reminder';
const REMINDER_UPDATE_ERROR_IDENTIFIER = 'update-reminder';
const REMINDER_DISMISS_ERROR_IDENTIFIER = 'dismiss-reminder';

export class RemindersFeature implements IRemindersFeature {
    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private userStore: UserStore,
        private errorsStore: ErrorsStore,
        private toasterStore: ToasterStore,
        private remindersApi: IRemindersApi,
        private notificationsStore: NotificationsStore,
        private quickActionStore: IQuickActionsStoreInterface,
        private dealsStore: DealsStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    setReminderToBeEdited = (
        launchSource: ActionFeatureLaunchSource,
        reminder?: Reminder,
    ) => {
        this.quickActionStore.setQuickAction({
            launchSource,
            entity: reminder,
            entityId: reminder?.id,
            dealId: reminder?.target.id,
            quickAction: ActionFeaturesIdentifiers.schedule_reminder,
        });
    };

    onReminderCreated = (
        value: ReminderResponse,
        errorKeyToClear: string,
        assigneeId: number,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        const newReminder = new Reminder(value);
        this.onReminderCreatedOrUpdated(errorKeyToClear, displaySuccessToaster);
        this.trackReminderCreatedEvent(value, assigneeId);
        if (dealId) {
            const deal = this.dealsStore.dealsMap.get(dealId);
            if (deal) {
                deal.reminders = deal?.reminders
                    ? [...deal.reminders, newReminder]
                    : [newReminder];
                this.dealsStore.dealsMap.set(dealId, { ...deal });
            }
        }
        onSuccess?.(newReminder);

        this.achievementService.call(UserAchievementName.PlanANextStep);
    };

    trackReminderCreatedEvent = (
        value: ReminderResponse,
        assigneeId: number,
    ) => {
        const deal = this.dealsStore.dealsMap.get(value.target.id);
        if (!deal) {
            return;
        }
        const numberOfDaysDueDateInFuture = -getNumberOfDaysAgo(
            new Date(value.dueDate),
        );
        const reminderSetToSomeoneElse =
            assigneeId !== this.userStore.user?.itemId;
        this.mixpanelService.trackEvent(MixpanelEventName.SetReminder, {
            pipelineId: deal.pipeline.id,
            dealId: deal.id,
            numberOfDaysDueDateInFuture,
            reminderSetToSomeoneElse,
            reminderText: value.description,
        });
    };

    onReminderUpdated =
        (
            errorKeyToClear: string,
            reminderId: string,
            dealId?: string,
            onSuccess?: (reminder: Reminder) => void,
            displaySuccessToaster = true,
        ) =>
        (value: ReminderResponse) => {
            const newReminder = new Reminder(value);
            this.onReminderCreatedOrUpdated(
                errorKeyToClear,
                displaySuccessToaster,
            );
            if (dealId) {
                const deal = this.dealsStore.dealsMap.get(dealId);
                if (deal?.reminders) {
                    deal.reminders = deal.reminders.map((reminder) => {
                        if (reminder.id === reminderId) {
                            return newReminder;
                        }
                        return reminder;
                    });

                    this.dealsStore.dealsMap.set(dealId, { ...deal });
                }
            }
            onSuccess?.(newReminder);
        };

    onReminderCreatedOrUpdated = (
        errorKeyToClear: string,
        displaySuccessToaster = true,
    ) => {
        this.quickActionStore.clean();
        this.errorsStore.clearError(errorKeyToClear);

        /**
         * This line is important as we need to re-fetch the alert from the server
         * after creating/updating a reminder to get a fresh alert type considering the hierarch
         */
        this.dealsStore.setNeedToSyncDealAlert(true);

        if (displaySuccessToaster) {
            this.toasterStore.showMessage({
                title: this.t(
                    'actions.reminders.reminder_set_successfully_message',
                ),
                type: 'success',
            });
        }
    };

    onReminderCreationOrUpdatedFailed = (errorKey: string, error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t(
                'actions.reminders.reminder_set_failed_message',
            ),
        });
    };

    createReminder = async (
        data: CreateReminderData,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const createdReminder = await handleRequestAsync(
                this.remindersApi.createReminder,
                {
                    ...data,
                    description: this.buildReminderDescription(
                        data.description,
                    ),
                    target: {
                        ...data.target,
                        type: TargetTypeModelToSdkMap[data.target.type],
                    },
                },
            );

            if (createdReminder) {
                this.onReminderCreated(
                    createdReminder,
                    REMINDER_CREATION_ERROR_IDENTIFIER,
                    data.assigneeId,
                    dealId,
                    onSuccess,
                    displaySuccessToaster,
                );
            }
        } catch (error) {
            this.onReminderCreationOrUpdatedFailed(
                REMINDER_CREATION_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    updateReminder = async (
        reminderId: string,
        data: UpdateReminderData,
        dealId?: string,
        onSuccess?: (reminder: Reminder) => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const updatedReminder = await handleRequestAsync(
                this.remindersApi.updateReminder,
                {
                    ...data,
                    reminderId,
                    description: this.buildReminderDescription(
                        data.description,
                    ),
                },
            );

            if (updatedReminder) {
                this.onReminderUpdated(
                    REMINDER_UPDATE_ERROR_IDENTIFIER,
                    reminderId,
                    dealId,
                    onSuccess,
                    displaySuccessToaster,
                )(updatedReminder);
            }
        } catch (error) {
            this.onReminderCreationOrUpdatedFailed(
                REMINDER_UPDATE_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    onDismissReminder = (
        reminderId: string,
        dealId?: string,
        onSuccess?: () => void,
        displaySuccessToaster = true,
    ) => {
        if (reminderId) {
            this.quickActionStore.clean();
            this.notificationsStore.dismissReminder(reminderId);
            if (displaySuccessToaster) {
                this.toasterStore.showMessage({
                    title: this.t(
                        'actions.reminders.reminder_dismissed_successfully_message',
                    ),
                    type: 'success',
                });
            }
            this.errorsStore.clearError(REMINDER_DISMISS_ERROR_IDENTIFIER);
            const deal = this.dealsStore.dealsMap.get(dealId ?? '');

            /**
             * This line is important as we need to re-fetch the alert from the server
             * after dismissing a reminder to get a fresh alert type considering the hierarch
             */
            if (deal) {
                this.dealsStore.setNeedToSyncDealAlert(true);
            }

            if (deal && deal.reminders) {
                deal.reminders = deal.reminders.filter(
                    ({ id }) => id !== reminderId,
                );
                this.dealsStore.dealsMap.set(deal.id, { ...deal });
            }
            if (onSuccess) {
                onSuccess();
            }

            this.achievementService.call(UserAchievementName.ResolveANextStep);
        }
    };

    dismissReminder = async (
        reminderId: string,
        dealId?: string,
        onSuccess?: () => void,
        displaySuccessToaster = true,
    ) => {
        try {
            const dismissedId = await handleRequestAsync(
                this.remindersApi.dismissReminder,
                { reminderId },
            );

            if (dismissedId) {
                this.onDismissReminder(
                    dismissedId,
                    dealId,
                    onSuccess,
                    displaySuccessToaster,
                );
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                REMINDER_DISMISS_ERROR_IDENTIFIER,
                error as Error,
            );
        }
    };

    buildReminderDescription = (rawDescription?: string) => {
        return rawDescription !== undefined && rawDescription.length > 0
            ? rawDescription
            : this.t('actions.reminders.note_default_text');
    };
}
